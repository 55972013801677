window.onload = function () {
  sticky(200);
};

function sticky(offset) {
  // Sticky navigation
  let scrollPos = window.scrollY;

  const navbar = document.querySelector(".navbar");

  window.addEventListener("scroll", () => {
    scrollPos = window.scrollY;
    passive = true;

    if (scrollPos >= offset) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  });
}

